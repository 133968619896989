import React from 'react';
import ConfigureOneStep from './ConfigureOneStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export default class ConfigureScenarioSteps extends React.Component {
    render() {
        let currentSampleGenerator = this.props.getSampleGenerator();

        if (!currentSampleGenerator.scenario.steps || currentSampleGenerator.scenario.steps.length === 0) {
            currentSampleGenerator.scenario.steps = [];
        }

        let allSteps = this.props.scenarios[currentSampleGenerator.scenario.kind].StepDefinitions;

        // Show all configure steps

        let stepsList = currentSampleGenerator.scenario.steps.map((step, index) => {
            return (
                <ConfigureOneStep className="mb-3" {...this.props} step={step} allSteps={allSteps} key={index} index={index}/>
            )
        })

        let addNewStepButton = (
            <button className="btn btn-primary mb-3" onClick={() => {
                currentSampleGenerator.scenario.steps.push({'type': null, 'params': []});
                this.props.updateSampleGenerator(currentSampleGenerator);
            }}>
                <FontAwesomeIcon icon={faPlus} />
            </button>
        );

        let deleteStepButton = (
            <button className="btn btn-danger mb-3" onClick={() => {
                currentSampleGenerator.scenario.steps.pop();
                this.props.updateSampleGenerator(currentSampleGenerator);
            }}>
                <FontAwesomeIcon icon={faMinus} />
            </button>
        );

        let buttonGroup = (
            <div className="btn-group mb-3" role="group" aria-label="Basic example">
                {addNewStepButton}
                {deleteStepButton}
            </div>
        );

        return (
            <>
                <h3>Configure steps</h3>

                {stepsList}


                {buttonGroup}
            </>
        )
    }
}
