// Create a new Monitoring component
import React from 'react';
import { Route, Switch } from 'react-router-dom';


import SampleList from '../Component/Monitoring/SampleList.js';
import ViewSample from '../Component/Monitoring/ViewSample.js';

// Create monitoring component class
export default class Monitoring extends React.Component {

    render() {
        return (
            <>
                <Switch>
                    <Route path="/monitoring" exact>
                        <SampleList style={{ 'marginTop': '100px' }} {...this.props} />
                    </Route>
                    <Route path="/monitoring/sample/:sampleid">
                        <ViewSample {...this.props}/>
                    </Route>
                </Switch>
            </>

        );
    }

}