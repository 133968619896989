// Create a new Monitoring component
import React from 'react';
import { Alert, Card, Container, Col, Form, Button, Image, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faLock } from '@fortawesome/free-solid-svg-icons';
import AccountImage from '../static/media/account.jpg'

// Create monitoring component class
export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: '',
            errorMessage: '',
            twofacode: '',
            enable2fa: false,
            isLoading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        let services = this.props.getServices();

        this.setState({
            isLoading: true
        });

        services['api'].login(this.state.email, this.state.password, this.state.twofacode).then(
            (response) => {
                if (response.TwoFactorEnabled) {
                    this.setState({
                        errorMessage: '2FA_MISSING',
                        enable2fa: true,
                        isLoading: false
                    });

                    return;
                }

                this.setState({
                    isLoading: false
                });
                window.localStorage.setItem('authToken', response.AuthToken);
                services['api'].setAuthToken(response.AuthToken);

                if (services['redirect'].getRedirect() === window.location.href) {
                    services['redirect'].setRedirect('/');
                }

                document.location.href = services['redirect'].getRedirect();
            },
            (error) => {
                this.setState({
                    isLoading: false
                });
                this.setState({
                    errorMessage: error.Error
                });
            }
        );
    }

    render() {
        let errorMessage;

        if (this.state.errorMessage) {
            errorMessage = <Alert variant="danger">{this.state.errorMessage}</Alert>;
        }

        let enable2fa;

        if (this.state.enable2fa) {
            enable2fa = <Form.Group className="mb-3"  controlId="formBasicPassword">
                <Form.Label>
                    <FontAwesomeIcon icon={faKey} />
                    &nbsp;
                    2FA Code
                </Form.Label>
                <Form.Control type="password" name="twofacode" placeholder="2FA Code" onChange={this.handleChange} />
            </Form.Group>;
        }


        return (
            <Container>
                <Col md={{ span: 6, offset: 3 }}>
                    <Card>
                        <Card.Header as="h3"><FontAwesomeIcon icon={faLock} /> Log in</Card.Header>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <Image src={AccountImage} fluid />
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                            {errorMessage}
                            <Form>
                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label><FontAwesomeIcon icon={faUser} /> Email</Form.Label>
                                    <Form.Control name="email" onChange={this.handleChange} type="text" placeholder="Enter email" />
                                    <Form.Text className="text-muted">
                                        Your username is the email address you use to log in.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label><FontAwesomeIcon icon={faKey} /> Password</Form.Label>
                                    <Form.Control name="password" onChange={this.handleChange} type="password" placeholder="Enter password" />
                                    <Form.Text className="text-muted">
                                        Your password is the password you use to log in.
                                    </Form.Text>
                                </Form.Group>

                                {enable2fa}

                                <Button disabled={this.state.isLoading} variant="primary" type="submit" size="lg" onClick={this.handleSubmit}>
                                    Submit
                                </Button>
                            </Form>

                        </Card.Body>
                    </Card>
                </Col>
            </Container>
        );
    }
}