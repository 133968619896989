import React from 'react';
import { Form } from 'react-bootstrap'

export default class ConfigureOneStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step,
        }

        this.changeStepType = this.changeStepType.bind(this);
    }

    changeStepType(e) {
        let currentSampleGenerator = this.props.getSampleGenerator();
        currentSampleGenerator['scenario']['steps'][this.props.index]['type'] = e.target.value;
        currentSampleGenerator['scenario']['steps'][this.props.index]['params'] = {};
        this.props.updateSampleGenerator(currentSampleGenerator);
    }

    render() {
        let currentSampleGenerator = this.props.getSampleGenerator();

        let selectOptions = Object.keys(this.props.allSteps).map((option, index) => {
            return (
                <option key={index} value={option}>{option}: {this.props.allSteps[option].Description}</option>
            )
        })

        let parameterInputs;
        if (currentSampleGenerator['scenario']['steps'][this.props.index]['type']) {
            // Create a textbox for every parameter
            let parameters = this.props.allSteps[currentSampleGenerator['scenario']['steps'][this.props.index]['type']].Params;
            parameterInputs = Object.keys(parameters).map((parameter, index) => {
                let optionalLabel = '';
                if (parameters[parameter].Optional) {
                    optionalLabel = ' (optional)';
                }

                let defaultValue = '';
                if (currentSampleGenerator['scenario']['steps'][this.props.index]['params'][parameters[parameter].Name]) {
                    defaultValue = currentSampleGenerator['scenario']['steps'][this.props.index]['params'][parameters[parameter].Name];
                }

                return (
                    <Form.Group key={parameters[parameter].Name}>
                        <Form.Label>{parameters[parameter].Name}{optionalLabel}</Form.Label>
                        <Form.Control type="text" defaultValue={defaultValue} placeholder={parameters[parameter].Name} onChange={(e) => {
                            let currentSampleGenerator = this.props.getSampleGenerator();
                            currentSampleGenerator['scenario']['steps'][this.props.index]['params'][parameters[parameter].Name] = e.target.value;
                            this.props.updateSampleGenerator(currentSampleGenerator);
                        }} />

                        <Form.Text className="text-muted">
                            {parameters[parameter].Description}
                        </Form.Text>
                    </Form.Group>
                )
            })

            // Add checkbox to parameterInputs for negate step
            parameterInputs.push(
                <Form.Group key={'negate'}>
                    <Form.Check type="checkbox" label="Negate" onChange={(e) => {
                        let currentSampleGenerator = this.props.getSampleGenerator();
                        currentSampleGenerator['scenario']['steps'][this.props.index]['negate'] = e.target.checked;
                        this.props.updateSampleGenerator(currentSampleGenerator);
                    }} />
                </Form.Group>
            )

        }

        return (
            <div className="mb-3">
                <h5>Configure one step</h5>
                <Form>
                    <Form.Group controlId="formScenarioKind">
                        <Form.Label>Step type</Form.Label>
                        <Form.Control defaultValue={currentSampleGenerator['scenario']['steps'][this.props.index]['type']} onChange={this.changeStepType} id="scenario_kind" as="select">
                            <option value=''>Please, select one scenario kind</option>
                            {selectOptions}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Select step type
                        </Form.Text>
                    </Form.Group>

                    {parameterInputs}
                </Form>
            </div>
        )
    }
}