import React from 'react';
import { Button, Modal, InputGroup, FormControl, FormGroup, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUserAstronaut, faLaptop } from '@fortawesome/free-solid-svg-icons';

export default class EditAgentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: {},
        };

        this.addNewTag = this.addNewTag.bind(this);
        this.createNewHidraAgent = this.createNewHidraAgent.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentWillMount() {
        if (this.props.tags) {
            this.setState({ tags: this.props.tags });
        }
    }

    addNewTag() {
        let currentTags = this.state.tags;
        currentTags[''] = '';

        this.setState({
            tags: currentTags,
        });
    }

    createNewHidraAgent() {
        let services = this.props.getServices();
        let agentName = document.getElementById('agent-name').value;
        let agentDescription = document.getElementById('agent-description').value;
        let body = {
            Name: agentName,
            Description: agentDescription,
            Tags: this.state.tags,
        };

        let promise = null;
        if (this.props.agentid) {
            promise = services['api'].updateAgent(this.props.agentid, body);

        } else {
            promise = services['api'].registerAgent(body);
        }
        promise.then(() => {
            if (this.props.onUpdate) {
                this.props.onUpdate();
            }
            services['globalSuccess']('Agent created');
        }).catch((err) => {
            services['globalError'](err.Error);
        }).finally(() => {
            this.props.onClose();
        });
    }

    removeTag(e) {
        let currentTags = this.state.tags;

        delete currentTags[e.target.dataset.key];

        this.setState({
            tags: currentTags,
        });
    }

    handleChange(e) {
        let currentTags = this.state.tags;

        if (e.target.dataset.isKey) {
            let newTags = {};

            for (let key in currentTags) {
                if (key === e.target.dataset.key) {
                    newTags[e.target.value] = currentTags[key];
                } else {
                    newTags[key] = currentTags[key];
                }
            }

            this.setState({
                tags: newTags,
            });
        } else {
            currentTags[e.target.dataset.key] = e.target.value;

            this.setState({
                tags: currentTags,
            });
        }


    }

    render() {
        let saveAgentText = 'Create new agent';

        if (this.props.agentid) {
            saveAgentText = 'Update agent';
        }

        let tagsInput = [];

        for (let key in this.state.tags) {
            if (this.state.tags.hasOwnProperty(key)) {
                tagsInput.push(
                    <InputGroup key="{key}" className="mb-3">
                        <FormControl data-is-key="true" data-key={key} onChange={this.handleChange} defaultValue={key} aria-label="key" />
                        <FormControl onChange={this.handleChange} data-key={key} defaultValue={this.state.tags[key]} aria-label="value" />
                        <Button data-key={key} variant="danger" onClick={this.removeTag}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </InputGroup>
                );
            }
        }

        return (
            <Modal show={this.props.show} onHide={this.props.onClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Creating a new Hidra agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup className="mb-3">
                        <Form.Label><FontAwesomeIcon icon={faUserAstronaut} /> Agent name</Form.Label>
                        <FormControl
                            id="agent-name"
                            defaultValue={this.props.agentName}
                            placeholder="Agent name"
                            aria-label="Agent name"
                            aria-describedby="basic-addon1"
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Form.Label><FontAwesomeIcon icon={faLaptop} /> Agent description</Form.Label>
                        <FormControl
                            as="textarea"
                            defaultValue={this.props.agentDescription}
                            id="agent-description"
                            placeholder="Agent description"
                            aria-label="Agent description"
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        {tagsInput}
                    </FormGroup>

                    <Button variant="secondary" onClick={this.addNewTag}>
                        Add new tag
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.createNewHidraAgent}>
                        {saveAgentText}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}