// Create a new Monitoring component
import React from 'react';

import Settings from '../static/media/settings.min.webp'

import { Card, Image, Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';

import validator from 'validator'

// Create monitoring component class
export default class Setup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: '',
            errorMessage: '',
            isLoading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.email || !validator.isEmail(this.state.email)) {
            this.setState({
                errorMessage: 'INVALID_EMAIL_ADDRESS'
            });
            return;
        }

        if (!this.state.password) {
            this.setState({
                errorMessage: 'INVALID_PASSWORD'
            });
            return;
        }

        let services = this.props.getServices();

        this.setState({ isLoading: true });

        services.api.setup({
            Email: this.state.email,
            Password: this.state.password,
        }).then(
            (response) => {
                document.location = '/';
            },
            (error) => {
                this.setState({
                    isLoading: false
                });
                this.setState({
                    errorMessage: error.Error
                });
            }
        );
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        let errorMessage;

        if (this.state.errorMessage) {
            errorMessage = <Alert variant="danger">{this.state.errorMessage}</Alert>;
        }

        return (
            <>
                <Card className="mb-3">

                    <Card.Header>
                        <h5>Setup</h5>
                    </Card.Header>

                    <Card.Body>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Image src={Settings} fluid />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>

                        <Form>
                            {errorMessage}
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label><FontAwesomeIcon icon={faUser} /> Root admin email</Form.Label>
                                <Form.Control name="email" onChange={this.handleChange} type="text" placeholder="Enter email" />
                                <Form.Text className="text-muted">
                                    Your username is the email address you use to log in.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label><FontAwesomeIcon icon={faKey} /> Root admin password</Form.Label>
                                <Form.Control name="password" onChange={this.handleChange} type="password" placeholder="Enter password" />
                                <Form.Text className="text-muted">
                                    Your password is the password you use to log in.
                                </Form.Text>
                            </Form.Group>


                            <Button disabled={this.state.isLoading} variant="primary" type="submit" size="lg" onClick={this.handleSubmit}>
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </>

        );
    }

}