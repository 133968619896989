import React from 'react';
import { Spinner, Table } from 'react-bootstrap'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

class SampleResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'isLoading': true,
            'sampleResult': [],
        }

        this.refreshDataTimeout = null;
    }

    async componentWillMount() {
        this.refreshState();
        this.refreshDataTimeout = setInterval(() => {
            this.refreshState();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshDataTimeout);
    }

    refreshState() {
        let services = this.props.getServices()

        services['api'].getSampleResult(this.props.sampleid).then(sampleResult => {
            this.setState({
                'isLoading': false,
                'sampleResult': sampleResult,
            })
        }).catch(err => {
            services.globalError(err.Error);
            this.setState({ isLoading: false })
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        let tableRows = this.state.sampleResult.map(sample => {
            let resultFlagColor = '';

            if (sample.Error === "") {
                resultFlagColor = 'green';
            } else {
                resultFlagColor = 'red';
            }

            let statusText = 'OK';

            if (sample.Error !== "") {
                statusText = sample.Error;
            }


            let resultFlag = <FontAwesomeIcon icon={faFlag} style={{"color": resultFlagColor}} />

            return (
                <tr key={sample.ID}>
                    <td style={{'width': '10px', 'textAlign': 'center'}} >{resultFlag}</td>
                    <td style={{'textAlign': 'center'}}>{statusText}</td>
                    <td style={{'textAlign': 'center'}}>{sample.Agent.Name} ({sample.Agent.Description })</td>
                    <td  style={{'width': '200px', 'textAlign': 'center'}}><Moment fromNow>{sample.StartDate}</Moment></td>
                </tr>
            )
        })

        return (
            <div>
                <h3>Sample Result</h3>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Result</th>
                            <th>Status</th>
                            <th>Agent</th>
                            <th>Execution</th>
                        </tr>
                    </thead>
                    <tbody>
                       { tableRows }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default SampleResult;