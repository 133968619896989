export default class Redirect {
    constructor() {
        this.url = '/';
    }
    
    setRedirect(url) {
        this.url = url;
    }

    getRedirect() {
        return this.url;
    }
}