// Create account setting page
import React from 'react';
import { Card, Container, Row, Col, Image } from 'react-bootstrap'
import Security from '../Component/Account/Security';
import AccountImage from '../static/media/account.jpg'


export default class Account extends React.Component {
    render() {
        return (
            <>
                <Card className="mb-3">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Image src={AccountImage} fluid />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>

                <Security {...this.props}  />
            </>
        );
    }
}