import React from "react";
import { Card, Spinner, Button, Badge, InputGroup, FormControl, Container, Row, Col, Image } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Moment from 'react-moment';
import EditAgentModal from './EditAgentModal';
import AgentImg from '../../static/media/agents.min.webp'

export default class AgentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'isLoading': true,
            'agents': [],
            'search': '',
            'agentModalIsOpen': false,
        }

        this.onSearch = this.onSearch.bind(this);
        this.handleOnUpdateAgents = this.handleOnUpdateAgents.bind(this);
        this.handleCloseAgentModal = this.handleCloseAgentModal.bind(this);
        this.handleOpenAgentModal = this.handleOpenAgentModal.bind(this);
    }


    async componentWillMount() {
        this.refreshState();
    }

    refreshState() {
        let services = this.props.getServices()

        services['api'].getAgents(this.state.search).then(agents => {
            this.setState({
                'isLoading': false,
                'agents': agents,
            })
        }).catch(err => {
            services.globalError(err.Error);
            this.setState({ isLoading: false })
        });
    }

    chunk(arr, len) {

        var chunks = [],
            i = 0,
            n = arr.length;

        while (i < n) {
            chunks.push(arr.slice(i, i += len));
        }

        return chunks;
    }

    handleOnUpdateAgents() {
        this.refreshState();
    }

    handleCloseAgentModal() {
        this.setState({
            agentModalIsOpen: false,
        });
    }

    onSearch(e) {
        this.setState({
            'search': e.target.value,
        });

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.refreshState.bind(this), 500);
    }

    handleOpenAgentModal(e) {
        this.setState({
            agentModalIsOpen: true,
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        let renderedCards = [];

        for (let agent of this.state.agents) {
            let tags = [];

            for (let tag in agent.Tags) {
                tags.push(
                    <Badge key={tag} pill bg="primary">
                        {tag}: {agent.Tags[tag]}
                    </Badge>
                )
            }

            renderedCards.push(
                <Card key={agent.ID} className="mb-3">
                    <Card.Header as="h3">{agent.Name}</Card.Header>

                    <Card.Body>
                        <Card.Text>
                            <div className="mb-3">
                                {agent.Description}
                            </div>
                            {tags}
                        </Card.Text>

                        <NavLink to={'/agents/agent/' + agent.ID} className="btn btn-primary">View agent</NavLink>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated <Moment fromNow>{agent.UpdatedAt}</Moment></small>
                    </Card.Footer>
                </Card>
            )
        }

        return (
            <>
                <Card className="mb-3">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Image src={AgentImg} fluid />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>

                        <InputGroup>
                            <Button onClick={this.handleOpenAgentModal} variant="primary">Create new agent</Button>

                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            <FormControl
                                onChange={this.onSearch}
                                placeholder="Search..."
                            />
                        </InputGroup>

                    </Card.Body>
                </Card>

                {renderedCards}

                <EditAgentModal show={this.state.agentModalIsOpen} onUpdate={this.handleOnUpdateAgents} onClose={this.handleCloseAgentModal} {...this.props} />
            </>

        );
    }

}