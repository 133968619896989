// Create welcome react component
import React from 'react';
import AgentList from '../Component/Agent/AgentList';
import { Route, Switch } from 'react-router-dom';
import ViewAgent from '../Component/Agent/ViewAgent';

export default class Agent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        };
    }

    render() {
        return (
            <>
                <Switch>
                    <Route path="/agents" exact>
                        <AgentList {...this.props} />

                    </Route>
                    <Route path="/agents/agent/:agentid">
                        <ViewAgent {...this.props} />
                    </Route>
                </Switch>
            </>
        );
    }
}