import React from 'react';
import { Spinner, Card } from 'react-bootstrap'
import Plot from 'react-plotly.js';


class SampleMetric extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'isLoading': true,
            'metrics': [],
        }

        this.refreshDataTimeout = null;
    }

    async componentWillMount() {
        this.refreshState();

        this.refreshDataTimeout = setInterval(() => {
            this.refreshState();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshDataTimeout);
    }

    refreshState() {
        let services = this.props.getServices()

        services['api'].getMetrics(this.props.sampleid).then(metrics => {
            this.setState({
                'isLoading': false,
                'metrics': metrics,
            })
        }).catch(err => {
            services.globalError(err.Error);
            this.setState({ isLoading: false })
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        let metricsData = this.state.metrics.map(metric => {
            let x = [];
            let y = [];

            for (let i = 0; i < metric.Values.length; i++) {
                x.push(new Date(metric.Values[i].Time * 1000));

                if (metric.MetricName.indexOf('time') !== -1) {
                    metric['Values'][i].Value = metric['Values'][i].Value / 1000000;
                }

                y.push(metric.Values[i].Value);
            }

            let labelJoin = '';
            for (let metricKey in metric.Labels) {
                if ([ 'agent_id', 'checksum', 'agent_name', 'sample_id', 'sample_name'].indexOf(metricKey) !== -1) {
                    continue
                }
                labelJoin += metricKey + '=' + metric.Labels[metricKey] + ',';
            }

            labelJoin = labelJoin.substring(0, labelJoin.length - 1);
            return {
                x,
                y,
                name: metric.MetricName, // + '{' + labelJoin + '}',
                type: 'scatter',
                text: metric.MetricName + '{' + labelJoin + '}',
           };
        });

        return (
            <Card style={{ 'marginBottom': '10px' }}>
                <Card.Body>
                    <Plot
                            layout={{
                                autosize: true,
                                showlegend: true,
                                legend: {
                                  // x: 1, y: 0
                                }
                            }}
                            style={{'width': '100%'}}
                            data={metricsData}
                    />
                </Card.Body>
            </Card>

        );
    }
}

export default SampleMetric;
