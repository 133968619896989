import React from 'react';
import { Card, Alert, Form, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode.react';

export default class Security extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            'errorMessageUpdatePassword': null,
            'successMessageUpdatePassword': null,
            '2faConfiguration': null,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitUpdatePassword = this.handleSubmitUpdatePassword.bind(this);
        this.handleConfigure2fa = this.handleConfigure2fa.bind(this);
        this.handleDisable2fa = this.handleDisable2fa.bind(this);
    }



    async componentDidMount() {
        this.refresh2faState();
    }

    async refresh2faState() {
        // Get API
        let services = this.props.getServices();
        let api = services['api'];

        // Get 2FA configuration
        try {
            this.setState({
                '2faConfiguration': await api.get2faToken()
            });
        } catch (e) { }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleDisable2fa(e) {
        e.preventDefault();

        // Get API
        let services = this.props.getServices();
        let api = services['api'];

        api.disable2fa().then(async () => {
            await this.refresh2faState();
        });
    }

    handleConfigure2fa(e) {
        e.preventDefault();

        // Get API
        let services = this.props.getServices();
        let api = services['api'];

        // Get 2FA configuration
        api.configure2fa(this.state['2faConfiguration'].MFASecret, this.state['2fa-code'])
            .then(_ => {
                this.setState({
                    '2faConfiguration': null
                });
            })
    }

    handleSubmitUpdatePassword(e) {
        e.preventDefault();

        // Get API
        let services = this.props.getServices();
        let api = services['api'];

        let oldPassword = this.state['old-password'];
        let newPassword = this.state['new-password'];
        let newRepassword = this.state['new-repassword'];

        if (newPassword.length < 4) {
            this.setState({
                'errorMessageUpdatePassword': 'SMALL_PASSWORD'
            });
            return;
        }

        if (newPassword !== newRepassword) {
            this.setState({
                'errorMessageUpdatePassword': 'NOT_MATCHING_REPASSWORD'
            });
            return;
        }

        api.updatePassword(oldPassword, newPassword).then(() => {
            this.setState({
                'errorMessageUpdatePassword': null,
                'successMessageUpdatePassword': 'ACTION_SUCCESS'
            });
        }).catch((error) => {
            this.setState({
                'errorMessageUpdatePassword': 'WRONG_PASSWORD'
            });
        }
        );
    }

    render() {
        let errorMessageUpdatePassword = null;
        if (this.state.errorMessageUpdatePassword) {
            errorMessageUpdatePassword = <Alert variant="danger">{this.state.errorMessageUpdatePassword}</Alert>
        }

        let successMessageUpdatePassword = null;
        if (this.state.successMessageUpdatePassword) {
            successMessageUpdatePassword = <Alert variant="success">{this.state.successMessageUpdatePassword}</Alert>
        }

        let qrCode = null;
        let disable2faButton = null;
        let configure2faForm = null;

        if (this.state['2faConfiguration']) {
            qrCode = <QRCode value={this.state['2faConfiguration'].URI} />
            configure2faForm = (
                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Verify 2Fa</Form.Label>
                    <Form.Control className="mb-3"  name="2fa-code" onChange={this.handleChange} type="text" placeholder="Enter 2fa" />

                    <Button variant="primary" type="submit" size="lg" onClick={this.handleConfigure2fa}>
                        Submit
                    </Button>
                </Form.Group>


            )
        } else {
            disable2faButton = <Button variant="danger" onClick={this.handleDisable2fa}>Disable 2FA</Button>
        }


        return (
            <Row>
                <Col className="mb-3">
                    <Card>
                        <Card.Header as="h3"><FontAwesomeIcon icon={faLock} /> Password manager</Card.Header>

                        <Card.Body>
                            {successMessageUpdatePassword}
                            {errorMessageUpdatePassword}
                            <Form>
                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label>Your current password</Form.Label>
                                    <Form.Control name="old-password" onChange={this.handleChange} type="password" placeholder="Enter password" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label>Your new password</Form.Label>
                                    <Form.Control name="new-password" onChange={this.handleChange} type="password" placeholder="Enter password" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPassword">
                                    <Form.Label>Repear your new password</Form.Label>
                                    <Form.Control name="new-repassword" onChange={this.handleChange} type="password" placeholder="Enter password" />
                                </Form.Group>

                                <Button variant="primary" type="submit" size="lg" onClick={this.handleSubmitUpdatePassword}>
                                    Submit
                                </Button>
                            </Form>
                            <Card.Text>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="mb-3">
                    <Card>
                        <Card.Header as="h3"><FontAwesomeIcon icon={faLock} /> 2FA</Card.Header>

                        <Card.Body>
                            <Form>
                                {qrCode}
                                {disable2faButton}
                                {configure2faForm}
                            </Form>
                            <Card.Text>
                            </Card.Text>
                        </Card.Body>
                    </Card></Col>
            </Row>

        )
    }
}