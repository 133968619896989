import './static/css/App.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap'

import Monitoring from './Pages/Monitoring.js';
import Login from './Pages/Login.js';
import Welcome from './Pages/Welcome.js';
import Agent from './Pages/Agent';
import Setup from './Pages/Setup.js';

import Navbar from './Component/Navbar/Navbar.js';

import API from './Services/API.js';
import RedirectService from './Services/Redirect.js';

import ProtectedRoute from './Component/Route/ProtectedRoute';
import Footer from './Component/Navbar/Footer';
import Account from './Pages/Account';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      'isLoading': true,
      'globalError': null,
      'globalSuccess': null,
      'globalWarning': null,
      'setupStatus': null,
    }

    this.globalError = this.globalError.bind(this);
    this.globalSuccess = this.globalSuccess.bind(this);
    this.globalWarning = this.globalWarning.bind(this);

    this.services = {};
    this.services.api = new API();
    this.services.redirect = new RedirectService();

    this.services.globalError = this.globalError;
    this.services.globalSuccess = this.globalSuccess;
    this.services.globalWarning = this.globalWarning;

    this.getServices = this.getServices.bind(this);
  }

  async componentWillMount() {
    let setupStatus = await this.services.api.setupStatus();
    if (!setupStatus.Status) {
      this.setState({ 'setupStatus': true, isLoading: false });
      return;
    }
    let authToken = localStorage.getItem('authToken');

    if (authToken) {
      try {
        await this.services['api'].setAuthToken(authToken);
        this.services.system_info = await this.services['api'].systemInfo();

        if (this.services.system_info['DbType'] === 'sqlite') {
          this.globalWarning('You are using SQLite database. Please use a different database type. This is only for development purposes');
        }
      } catch (e) {
        console.log(e);
        localStorage.clear();
      }
    }

    this.setState({ isLoading: false })
  }

  getServices() {
    return this.services;
  }

  globalError(err) {
    this.setState({ globalError: err });
  }

  globalSuccess(msg) {
    this.setState({ globalSuccess: msg });
  }

  globalWarning(msg) {
    this.setState({ globalWarning: msg });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <Spinner animation="border" role="status" variant="info" className="loader">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )
    }

    let errorMessage;

    if (this.state.globalError) {
      errorMessage = <Alert variant="danger" onClose={() => {this.setState({'globalError': null})}} dismissible>{this.state.globalError}</Alert>;
    }

    let successMessage;

    if (this.state.globalSuccess) {
      successMessage = <Alert variant="success" onClose={() => {this.setState({'globalSuccess': null})}} dismissible>{this.state.globalSuccess}</Alert>;
    }

    let warningMessage;

    if (this.state.globalWarning) {
      warningMessage = <Alert variant="warning" onClose={() => {this.setState({'globalWarning': null})}} dismissible>{this.state.globalWarning}</Alert>;
    }


    let redirectSetup;

    if (this.state.setupStatus) {
      redirectSetup = <Redirect to="/setup" />
    }

    return (
      <BrowserRouter>

        <Navbar getServices={this.getServices}> </Navbar>
        <Container style={{ 'marginTop': '100px', 'minHeight': '100vh' }}>
          <Row>
            <Col>
              {errorMessage}
              {successMessage}
              {warningMessage}

              <Switch>
                <Route path='/setup' exact render={(props) => (<Setup getServices={this.getServices} {...props} />)} />

                {redirectSetup}

                <Route path="/" exact>
                  <ProtectedRoute getServices={this.getServices} component={Welcome}></ProtectedRoute>
                </Route>
                <Route path='/login' exact render={(props) => (<Login getServices={this.getServices} {...props} />)} />
                <Route path='/monitoring'>
                  <ProtectedRoute getServices={this.getServices} component={Monitoring}></ProtectedRoute>
                </Route>
                <Route path='/account'>
                  <ProtectedRoute getServices={this.getServices} component={Account}></ProtectedRoute>
                </Route>
                <Route path='/agents' agents>
                  <ProtectedRoute getServices={this.getServices} component={Agent}></ProtectedRoute>
                </Route>

              </Switch>
            </Col>
          </Row>

        </Container>

        <Footer getServices={this.getServices} />
      </BrowserRouter>
    )
  }
}

export default App
