import React from 'react';
import { Form } from 'react-bootstrap'

export default class ConfigureScenarioKind extends React.Component {
    constructor(props) {
        super(props);
        this.changeScenarioKind = this.changeScenarioKind.bind(this);

    }

    changeScenarioKind(e) {
        let sampleGenerator = this.props.getSampleGenerator();

        if (!sampleGenerator['scenario']) {
            sampleGenerator['scenario'] = {};
        }

        sampleGenerator['scenario']['kind'] = e.target.value;
        this.props.updateSampleGenerator(sampleGenerator);
    }

    render() {
        let selectOptions = Object.keys(this.props.scenarios).map((option, index) => {
            return (
                <option key={index} value={option}>{option}: {this.props.scenarios[option].Description}</option>
            )
        })

        let defaultValue;
        if (this.props.getSampleGenerator()['scenario']) {
            defaultValue = this.props.getSampleGenerator()['scenario']['kind'];
        }

        return (
            <>
                <h3>Select one scenario kind</h3>
                <Form>
                    <Form.Group className="mb-3" controlId="formScenarioKind">
                        <Form.Label>Scenario kind</Form.Label>
                        <Form.Control defaultValue={defaultValue} onChange={this.changeScenarioKind} id="scenario_kind" as="select">
                            <option>Please, select one scenario kind</option>
                            {selectOptions}
                        </Form.Control>
                        <Form.Text className="text-muted">
                            Select the scenario kind
                        </Form.Text>
                    </Form.Group>
                </Form>
            </>
        )
    }
}
