import React from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Card, Spinner, Button, Tabs, Tab, Badge } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import EditAgentModal from './EditAgentModal';
import Moment from 'react-moment';

class ViewAgent extends React.Component {
    constructor(props) {
        super(props)

        let services = this.props.getServices()

        this.state = {
            agentModalIsOpen: false,
            'isLoading': true,
            version: services['system_info'].Version,
        }

        this.handleOnUpdateAgents = this.handleOnUpdateAgents.bind(this);
        this.handleCloseAgentModal = this.handleCloseAgentModal.bind(this);
        this.handleOpenAgentModal = this.handleOpenAgentModal.bind(this);
    }

    handleOnUpdateAgents() {
        this.refreshState();
    }

    handleCloseAgentModal() {
        this.setState({
            agentModalIsOpen: false,
        });
    }

    handleOpenAgentModal(e) {
        this.setState({
            agentModalIsOpen: true,
        });
    }

    async componentWillMount() {
        this.refreshState();
    }

    refreshState() {
        let services = this.props.getServices()

        services['api'].getAgent(this.props.match.params.agentid).then(agent => {
            this.setState({
                'agent': agent,
            })
            this.setState({ isLoading: false })
        }).catch(err => {
                services.globalError(err.Error);
                this.setState({ isLoading: false })
            });
    }

    handleOnUpdateAgent() {
        this.refreshState();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }


        let tags = [];

        for (let tag in this.state.agent.Tags) {
            tags.push(
                <Badge key={tag} pill bg="primary">
                    {tag}: {this.state.agent.Tags[tag]}
                </Badge>
            )
        }

        let apiURL = document.location.protocol + "//" + document.location.host + "/api";
        let dockerCmd = `docker run -d --restart unless-stopped ghcr.io/hidracloud/hidra:${this.state.version} -agent -agent_secret "${this.state.agent.Secret}" -api_url "${apiURL}"`;
        return (
            <>
                <h3>AgentID: {this.props.match.params.agentid} {tags}</h3>
                <Card style={{ 'marginBottom': '10px' }}>
                    <Card.Body>
                        <ButtonGroup className="mb-2">
                            <NavLink to='/agents/' className="btn btn-danger">Go back</NavLink>

                            <Button onClick={this.handleOpenAgentModal} variant="primary">Edit agent</Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>

                <Card key={this.state.agent.ID} className="mb-3">
                    <Card.Header as="h3">{this.state.agent.Name}</Card.Header>

                    <Card.Body>
                        <Card.Text>
                            <div className="mb-3">
                                {this.state.agent.Description}
                            </div>
                            {tags}
                        </Card.Text>

                        <NavLink to={'/agents/agent/' + this.state.agent.ID} className="btn btn-primary">View agent</NavLink>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated <Moment fromNow>{this.state.agent.UpdatedAt}</Moment></small>
                    </Card.Footer>
                </Card>

                <Card>
                    <Card.Header as="h2">
                        Deploy agent
                    </Card.Header>
                    <Card.Body>
                        <Tabs id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="home" title="Docker (Recommended)">
                                <ol>
                                    <li>Install docker</li>
                                    <li>Run the following command:
                                    <pre>{dockerCmd}</pre>
                                    </li>
                                </ol>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>

                <EditAgentModal agentName={this.state.agent.Name} agentDescription={this.state.agent.Description} agentid={this.state.agent.ID} tags={this.state.agent.Tags} show={this.state.agentModalIsOpen} onUpdate={this.handleOnUpdateAgents} onClose={this.handleCloseAgentModal} {...this.props} />
            </>
        );
    }
}

ViewAgent = withRouter(ViewAgent);
export default ViewAgent;