import React from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Card, Spinner, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import EditSampleModal from './EditSampleModal.js';
import SampleResult from './SampleResult.js';
import SampleMetric from './SampleMetric.js';
import YAML from 'yaml';

class ViewSample extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sampleModalIsOpen: false,
            'isLoading': true,
        }

        this.handleCloseSampleModal = this.handleCloseSampleModal.bind(this);
        this.handleOpenSampleModal = this.handleOpenSampleModal.bind(this);
        this.handleOnUpdateAgent = this.handleOnUpdateAgent.bind(this);
        this.handleRunSample = this.handleRunSample.bind(this);
        this.handleDeleteSample = this.handleDeleteSample.bind(this);
    }


    async componentWillMount() {
        this.refreshState();
    }

    refreshState() {
        let services = this.props.getServices()

        services['api'].getSample(this.props.match.params.sampleid).then(sample => {
            this.setState({
                'isLoading': false,
                'sample': sample,
                'sampleData': YAML.parse(sample),
            })
        }).catch(err => {
            services.globalError(err.Error);
            this.setState({ isLoading: false })
        });
    }

    handleDeleteSample() {
        let confirmDelete = window.confirm("Are you sure you want to delete this sample?");
        if (confirmDelete) {
            let services = this.props.getServices()

            services['api'].deleteSample(this.props.match.params.sampleid).then(() => {
                this.props.history.push('/monitoring')
            }).catch(err => {
                services.globalError(err.Error);
            });
        }
    }

    handleRunSample() {
        // Get sample
        let services = this.props.getServices();
        services['api'].runSample(this.state.sample).then(sample => {
            alert(sample)
        });
    }

    handleCloseSampleModal() {
        this.setState({
            sampleModalIsOpen: false,
        });
    }

    handleOpenSampleModal(e) {
        this.setState({
            sampleModalIsOpen: true,
        });
    }


    handleOnUpdateAgent() {
        this.refreshState();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        return (
            <>
                <Card style={{ 'marginBottom': '10px' }}>
                    <Card.Body>
                        <ButtonGroup className="mb-2">
                            <NavLink to='/monitoring/' className="btn btn-danger">Go back</NavLink>
                            <Button onClick={this.handleOpenSampleModal} variant="primary">Edit sample</Button>
                            <Button onClick={this.handleRunSample} variant="warning">Run sample</Button>
                            <Button onClick={this.handleDeleteSample} variant="danger">Delete sample</Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>

                <SampleResult sampleid={this.props.match.params.sampleid} sample={this.state.sample} {...this.props} />
                <SampleMetric sampleid={this.props.match.params.sampleid} sample={this.state.sample} {...this.props} />

                <EditSampleModal sampleid={this.props.match.params.sampleid} text={this.state.sample} show={this.state.sampleModalIsOpen} onUpdate={this.handleOnUpdateAgent} onClose={this.handleCloseSampleModal} {...this.props} />

            </>
        );
    }
}

ViewSample = withRouter(ViewSample);
export default ViewSample;