// Create welcome react component
import React from 'react';

export default class Welcome extends React.Component {
  render() {
    return (
      <div>
        <h1>Welcome to Hidra</h1>
        <p>
          This is the Hidra frontend.
        </p>
      </div>
    );
  }
}