import React from 'react';
import { Form } from 'react-bootstrap'

export default class SampleBasicInfo extends React.Component {
    constructor(props) {
        super(props);

        this.changeSampleGenerator = this.changeSampleGenerator.bind(this);
    }

    // Change sample generator name
    changeSampleGenerator(event) {
        let sampleGenerator = this.props.getSampleGenerator();
        sampleGenerator[event.target.id] = event.target.value;
        this.props.updateSampleGenerator(sampleGenerator);
    }

    render() {

        return (
            <>
                <h3>Configure your sample</h3>
                <Form>

                    <Form.Group className="mb-3" controlId="formSampleName">
                        <Form.Label>Sample name</Form.Label>
                        <Form.Control defaultValue={this.props.name} id="name" type="text" placeholder="Enter sample name" onChange={this.changeSampleGenerator} />
                        <Form.Text className="text-muted">
                            Add an sample name to identify the sample (For example, http_test_google_com_200_OK)
                        </Form.Text>
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formDescription">
                        <Form.Label>Sample description</Form.Label>
                        <Form.Control defaultValue={this.props.description} id="description" type="text" as="textarea" placeholder="Enter description" onChange={this.changeSampleGenerator} />
                        <Form.Text className="text-muted">
                            Add a description to the sample
                        </Form.Text>
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formScrapeInterval">
                        <Form.Label>Scrape interval</Form.Label>
                        <Form.Control defaultValue={this.props.scrapeInterval} id="scrapeInterval" placeholder="Enter scrape interval" onChange={this.changeSampleGenerator} />
                        <Form.Text className="text-muted">
                            Add a scrape interval in duration format (1s, 1m, 1h, 1d, ...)
                        </Form.Text>
                    </Form.Group>
                </Form>
            </>
        )
    }
}