import React from 'react';
import Logo from '../../static/media/logo.svg'

import { NavLink } from 'react-router-dom'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'

class NavbarCustom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedUser: null
        };

        this.getNavLinkClass = this.getNavLinkClass.bind(this);
    }

    async componentDidMount() {
        let services = this.props.getServices();

        if (services['api'].isLogged()) {
            let user = await services['api'].getMe();
            this.setState({
                loggedUser: user
            });
        }
    }

    logout() {
        localStorage.removeItem('authToken');
        document.location = '/';
    }

    render() {

        let loggedUserMenu = null;

        if (this.state.loggedUser) {
            loggedUserMenu = (
                <NavDropdown title={this.state.loggedUser.Email} id="basic-nav-dropdown">
                    <NavLink to="/account" className={ 'dropdown-item' }>Account</NavLink>
                    <NavDropdown.Item onClick={this.logout} as={NavLink} to="/logout">Logout</NavDropdown.Item>
                </NavDropdown>
            );
        }

        return (
            <Navbar bg='dark' variant='dark' fixed="top">
                <Container>
                    <NavLink to="/" className='navbar-brand'>
                        <img
                            alt=''
                            src={Logo}
                            width='30'
                            height='30'
                            className='d-inline-block align-top'
                        />{' '}
                        Hidra
                    </NavLink>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavLink to="/monitoring" className={ 'nav-link ' + this.getNavLinkClass("/monitoring") }>Monitoring</NavLink>
                            <NavLink to="/agents" className={'nav-link ' + this.getNavLinkClass("/agents")}>Agents</NavLink>
                        </Nav>
                    </Navbar.Collapse>

                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            {loggedUserMenu}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }

    getNavLinkClass = (path) => {
        console.log(this.props);
        return window.location.href === path ? 'active' : '';
    }
}

export default NavbarCustom;