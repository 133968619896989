import React from 'react';
import { Button, Modal, Spinner, Container, Row, Col, Image, ButtonGroup } from 'react-bootstrap'
import MonitoringImg from '../../static/media/monitoring.min.webp'
import SampleBasicInfo from './SampleModal/SampleBasicInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faForward, faBackward } from '@fortawesome/free-solid-svg-icons';

import ConfigureScenarioKind from './SampleModal/ConfigureScenarioKind';
import YAML from 'yaml'
import ConfigureScenarioSteps from './SampleModal/ConfigureScenarioSteps';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/theme-github";

const MAX_STEPS = 3;

function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

const initialState = {
    'scenarios': null,
    'creationStep': 1,
    'sampleGenerator': {},
    'advanceMode': false,
    'yaml': '',
}

export default class EditSampleModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = initialState;

        this.createNewHidraSample = this.createNewHidraSample.bind(this);
        this.updateSampleGenerator = this.updateSampleGenerator.bind(this);
        this.getSampleGenerator = this.getSampleGenerator.bind(this);
        this.handleTestSample = this.handleTestSample.bind(this);
        this.handleChangeMode = this.handleChangeMode.bind(this);
        this.handleOnChangeYaml = this.handleOnChangeYaml.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    getSampleGenerator() {
        return this.state.sampleGenerator;
    }

    updateSampleGenerator(sampleGenerator) {
        this.setState({
            'sampleGenerator': sampleGenerator
        })
    }

    handleTestSample() {
        let services = this.props.getServices();

        services['api'].runSample(this.getSampleGeneratorYaml()).then((result) => {
            alert(result)
        });
    }

    async refreshState() {
        let services = this.props.getServices()

        this.setState({
            'scenarios': await services['api'].getScenariosAndSteps()
        })
    }

    async componentWillMount() {
        let sampleGenerator = {};
        if (this.props.text) {
            sampleGenerator = YAML.parse(this.props.text);
        }

        this.setState({ sampleGenerator });

        this.refreshState();
    }

    handleOnChangeYaml(yaml) {
        this.setState({
            'yaml': yaml
        })
    }

    createNewHidraSample() {
        let sampleYaml;

        if (this.state.advanceMode) {
            sampleYaml = this.state.yaml;
        } else {
            sampleYaml = this.getSampleGeneratorYaml();
        }

        let services = this.props.getServices();

        let promise = null;
        if (this.props.sampleid) {
            promise = services['api'].updateSample(this.props.sampleid, sampleYaml);

        } else {
            promise = services['api'].registerSample(sampleYaml);
        }
        promise.then(() => {
            if (this.props.onUpdate) {
                this.props.onUpdate();
            }
            services['globalSuccess']('Sample updated');
        }).catch((err) => {
            services['globalError'](err.Error);
        }).finally(() => {
            this.onClose();
            this.setState({ creationStep: 1, advanceMode: false });
        });
    }

    onClose() {
        //this.setState(initialState);
        this.props.onClose();
    }

    // Convert sample generator to yaml
    getSampleGeneratorYaml() {
        return YAML.stringify(this.state.sampleGenerator);
    }

    handleChangeMode() {
        if (!this.state.advanceMode) {
            this.setState({
                'yaml': this.getSampleGeneratorYaml()
            });
        } else {
            this.setState({
                'sampleGenerator': YAML.parse(this.state.yaml)
            });
        }

        this.setState({
            'advanceMode': !this.state.advanceMode
        })
    }

    render() {
        if (this.state.scenarios === null) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        let saveSampleText = '';

        if (this.props.sampleid) {
            saveSampleText = '';
        }

        let creationStep;

        switch (this.state.creationStep) {
            default:
                creationStep = (
                    <SampleBasicInfo
                        name={this.state.sampleGenerator.name}
                        description={this.state.sampleGenerator.description}
                        scrapeInterval={this.state.sampleGenerator.scrapeInterval}
                        getSampleGenerator={this.getSampleGenerator}
                        updateSampleGenerator={this.updateSampleGenerator}
                        {...this.props} {...this.state}
                    />
                )
                break;
            case 2:
                creationStep = (
                    <ConfigureScenarioKind
                        scrapeInterval={this.state.sampleGenerator.scrapeInterval}
                        getSampleGenerator={this.getSampleGenerator}
                        updateSampleGenerator={this.updateSampleGenerator}
                        {...this.props} {...this.state}
                    />
                )
                break;
            case 3:
                creationStep = (
                    <ConfigureScenarioSteps
                        getSampleGenerator={this.getSampleGenerator}
                        updateSampleGenerator={this.updateSampleGenerator}
                        {...this.props} {...this.state}
                    />
                )
                break;
        }

        if (this.state.advanceMode) {
            creationStep = (
                <AceEditor
                    mode="sh"
                    theme="github"
                    defaultValue={this.state.yaml}
                    rows="1"
                    onChange={this.handleOnChangeYaml}
                    name="hidra-sample-yaml"
                    id="hidra-sample-yaml"
                    width="100%"
                    className="form-control" style={{ 'minWidth': '100%' }}
                />
            );
        }
        let saveButton;

        saveButton = (
            <Button variant="success" onClick={this.createNewHidraSample}>
                {saveSampleText} Save <FontAwesomeIcon icon={faSave} />
            </Button>
        )


        let previousStepButton;

        if (this.state.creationStep > 1 && !this.state.advanceMode) {
            previousStepButton = (
                <Button variant="secondary" onClick={() => { this.setState({ 'creationStep': this.state.creationStep - 1 }) }}>
                    Previous <FontAwesomeIcon icon={faBackward} />
                </Button>
            )
        }

        let nextStepButton;

        if (this.state.creationStep < MAX_STEPS && !this.state.advanceMode) {
            nextStepButton = (
                <Button variant="primary" onClick={() => { this.setState({ 'creationStep': this.state.creationStep + 1 }) }}>
                    Next <FontAwesomeIcon icon={faForward} />
                </Button>
            )
        }

        let dumpButton;
        let dumpFileName = 'sample.yaml';

        if (!this.state.advanceMode) {
            dumpButton = (
                <Button variant="dark" onClick={() => { download(this.getSampleGeneratorYaml(), dumpFileName, 'text/yaml') }}>
                    Dump
                </Button>
            )
        }

        let switchModeText = 'Switch to advance';

        if (this.state.advanceMode) {
            switchModeText = 'Switch to basic';
        }

        let editSample = creationStep;

        return (
            <Modal show={this.props.show} onHide={this.onClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{saveSampleText}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col></Col>
                            <Col>
                                <Image src={MonitoringImg} fluid />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            {editSample}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={this.handleChangeMode} variant="secondary">
                        {switchModeText}
                    </Button>

                    <ButtonGroup>
                        <Button onClick={this.handleTestSample} variant="warning">
                            Try it!

                        </Button>
                        {dumpButton}
                    </ButtonGroup>

                    <ButtonGroup>
                        {previousStepButton}
                        {nextStepButton}
                        {saveButton}

                    </ButtonGroup>


                </Modal.Footer>
            </Modal>
        )
    }
}