import React from 'react';
import {Pagination} from 'react-bootstrap';

export default class PaginationGenerator extends React.Component {
    changePage(page) {
        this.props.onChange(page);
    }

    render() {
        // Renderize page numbers
        let pageNumbers = [];
        for (let i = 1; i <= this.props.total_pages; i++) {
            pageNumbers.push(i);
        }

        return (
            <Pagination>
                <Pagination.First
                    onClick={() => this.changePage(0)}
                />
                <Pagination.Prev 
                    onClick={() => this.changePage(this.props.page - 1)}
                />

                {pageNumbers.map((number) => {
                    return (
                        <Pagination.Item
                            key={number}
                            active={number === this.props.page}
                            onClick={() => this.changePage(number - 1)}
                        >
                            {number}
                        </Pagination.Item>
                    );
                })}

                <Pagination.Next
                    onClick={() => this.changePage(this.props.page + 1)}
                />
                <Pagination.Last 
                    onClick={() => this.changePage(this.props.total_pages - 1)}
                />
            </Pagination>
        );
    }
}