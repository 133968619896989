// Represent API class
export default class API {
    constructor() {
        this.auth_token = null;
    }

    getPrefix() {
        // Check if development mode
        if (window.location.hostname === 'localhost') {
            return 'http://localhost:8080';
        }

        return document.location.protocol + "//" + document.location.host;
    }

    isLogged() {
        return this.auth_token !== null;
    }

    request(method, url, data, headers, json=true) {
        url = this.getPrefix() + url;

        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(method, url, true);

            if (json) {
                xhr.setRequestHeader('Content-Type', 'application/json');
            }
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.auth_token);
            xhr.onload = () => {
                let response = xhr.responseText;

                // Check if response has json header
                if (xhr.getResponseHeader('Content-Type') === 'application/json') {
                    response = JSON.parse(response);
                }
                if (xhr.status === 200) {
                    resolve(response);
                } else {
                    try {
                        reject(response);
                    } catch (e) {
                        reject({'Error': xhr.responseText});
                    }
                }
            };
            xhr.onerror = () => {
                reject(xhr.responseText);
            };

            if (json) {
                data = JSON.stringify(data);
            }

            xhr.send(data);
        });
    }

    async setAuthToken(token) {
        this.auth_token = token;

        return this.request('get', '/api/verify_token', null, null, false)
        .catch((err) => {
            this.auth_token = null;
            return Promise.reject(err);
        });
    }

    async configure2fa(mfaSecret, token) {
        return this.request('post', '/api/configure_2fa', {
            MFASecret: mfaSecret,
            CurrentToken: token
        });
    }

    async disable2fa() {
        return this.request('post', '/api/disable_2fa');
    }

    async get2faToken() {
        return this.request('get', '/api/2fa_qrcode');
    }

    async updatePassword(oldPassword, newPassword) {
        return this.request('post', '/api/update_password', {
            OldPassword: oldPassword,
            NewPassword: newPassword
        });
    }  
    
    async getMe() {
        return this.request('get', '/api/me');
    }

    async runSample(sample) {
        return this.request('post', '/api/sample_runner', sample, null, false);
    }

    async deleteSample(id) {
        return this.request('delete', '/api/delete_sample/' + id, null, null, false);
    }

    async login(username, password, token) {
        return this.request('post', '/api/login', {Email: username, Password: password, TwoFactorToken: token});
    }

    async getSamples(search = '', page) {
        return this.request('get', '/api/list_samples?search=' + encodeURI(search) + '&page=' + page);
    }

    async getScenariosAndSteps() {
        return this.request('get', '/api/list_scenarios_steps');
    }

    async getAgents(search = '') {
        return this.request('get', '/api/list_agents?search=' + encodeURI(search));
    }

    async ping() {
        return this.request('get', '/api/ping');
    }

    async systemInfo() {
        return this.request('get', '/api/system_info');
    }

    async setupStatus() {
        return this.request('get', '/api/setup_status');
    }

    async setup(body) {
        return this.request('post', '/api/create_setup', body);
    }

    async getAgent(agentid) {
        return this.request('get', '/api/get_agent/' + agentid);
    }

    async updateAgent(agentid, body) {
        return this.request('put', '/api/update_agent/' + agentid, body);
    }

    async registerAgent(body) {
        return this.request('post', '/api/register_agent', body);
    }

    async getSample(sampleid) {
        return this.request('get', '/api/get_sample/' + sampleid, null, null, false);
    }

    async getMetrics(sampleid) {
        return this.request('get', '/api/get_metrics/' + sampleid);
    }

    async getSampleResult(sampleid) {
        return this.request('get', '/api/get_sample_result/' + sampleid);
    }

    async registerSample(sample) {
        return this.request('post', '/api/register_sample', sample, null, false);
    }

    async updateSample(id, sample) {
        return this.request('put', '/api/update_sample/' + id, sample, null, false);
    }

    isAuthenticated() {
        return this.auth_token !== null;
    }
}
