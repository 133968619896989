import React from 'react';

import { Container, Nav, Navbar } from 'react-bootstrap'

export default class Footer extends React.Component {
    render() {
        let services = this.props.getServices();

        let hidraRelease = "latest"

        if (services['system_info']) {
            hidraRelease = services['system_info'].Version;
        }

        return (
            <footer id="footer">
                <Navbar expand='sm' bg="primary" variant="dark">
                    <Container>
                        <Nav className="me-auto">
                            <Nav.Link style={{'color': 'white' }} rel="nooponer" href={"https://github.com/hidracloud/hidra/releases/" + hidraRelease} target="_blank" noreferer>{hidraRelease}</Nav.Link>
                            <Nav.Link style={{'color': 'white' }} rel="nooponer" href="https://hidra.cloud" target="_blank" noreferer>Hidra docs</Nav.Link>
                            <Nav.Link style={{'color': 'white' }} rel="nooponer" href="https://github.com/hidracloud/hidra" target="_blank" noreferer>Github</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </footer>

        )
    }
}