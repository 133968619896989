// Create a new Monitoring component
import React from 'react';
import { Card, Spinner, Button, InputGroup, FormControl, Container, Row, Col, Image, ButtonGroup } from 'react-bootstrap'
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faExclamationCircle, faCheckCircle, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import EditSampleModal from './EditSampleModal.js';
import { NavLink } from 'react-router-dom'
import MonitoringImg from '../../static/media/monitoring.min.webp'
import Pagination from '../Pagination.js';


export default class SampleList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            'isLoading': true,
            'samples': [],
            'sampleModalIsOpen': false,
        }

        this.page = 0;
        this.onSearch = this.onSearch.bind(this);
        this.handleCloseSampleModal = this.handleCloseSampleModal.bind(this);
        this.handleOpenSampleModal = this.handleOpenSampleModal.bind(this);
        this.handleOnUpdateAgents = this.handleOnUpdateAgents.bind(this);
        this.handleRunSample = this.handleRunSample.bind(this);
        this.changePage = this.changePage.bind(this);
    }


    handleCloseSampleModal() {
        this.setState({
            sampleModalIsOpen: false,
        });
    }

    handleOpenSampleModal(e) {
        this.setState({
            sampleModalIsOpen: true,
        });
    }

    onSearch(e) {
        this.setState({
            'search': e.target.value,
        });

        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(this.refreshState.bind(this), 500);
    }

    handleOnUpdateAgents() {
        this.refreshState();
    }

    async componentWillMount() {
        this.refreshState();
    }

    deleteSample(sample_id) {
        let confirmDelete = window.confirm("Are you sure you want to delete this sample?");

        if (confirmDelete) {
            let services = this.props.getServices();
            services['api'].deleteSample(sample_id).then(data => {
                return this.refreshState();
            });
        }
    }

    handleRunSample(sample_id) {
        // Get sample data
        let services = this.props.getServices();
        services['api'].getSample(sample_id).then(data => {
            // Run sample
            services['api'].runSample(data).then(data => {
                alert(data);
            });
        });
    }
 
    refreshState() {
        let services = this.props.getServices()

        services['api'].getSamples(this.state.search, this.page).then(data => {
            this.setState({
                'isLoading': false,
                'samples': this.sortSamples(data.Items),
                'total': data.Total,
                'total_pages': Math.floor(data.Total / data.PageSize) + 1,
                'page_size': data.PageSize,
                'page': data.Page,
            })
        }).catch(err => {
            services.globalError(err.Error);
            this.setState({ isLoading: false })
        });
    }

    sortSamples(samples) {
        return samples.sort((a, b) => {
            if (a.Error < b.Error) {
                return 1;
            }
            if (a.Error > b.Error) {
                return -1;
            }
            return 0;
        });
    }

    changePage(page) {
        if (page < 0) {
            page = 0;
        }

        if (page > this.state.total_pages - 1) {
            page = this.state.total_pages - 1;
        }

        this.page = page;
        this.refreshState();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="info" className="loader">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )
        }

        let renderedCards = [];

        for (let sample of this.state.samples) {

            let errorIcon = <FontAwesomeIcon color="red" icon={faExclamationCircle} />;
            if (sample.Error.length === 0) {
                errorIcon = <FontAwesomeIcon color="green" icon={faCheckCircle} />;
            }

            renderedCards.push(
                <Card key={sample.Id} className={'mb-3 sample-type-' + sample.Kind}>
                    <Card.Header as="h3">{errorIcon} {sample.Kind}</Card.Header>

                    <Card.Body>
                        <Card.Title>{sample.Name}</Card.Title>

                        <Card.Text>
                            {sample.Description}
                        </Card.Text>


                        <ButtonGroup className="mb-2">

                            <NavLink to={'/monitoring/sample/' + sample.ID} className="btn btn-primary">View sample</NavLink>

                            <Button onClick={() => {this.handleRunSample(sample.ID) }} variant="warning">
                                Run sample
                            </Button>

                            <Button onClick={() => {this.deleteSample(sample.ID) }} variant="danger">
                                Delete sample
                            </Button>
                        </ButtonGroup>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated <Moment fromNow>{sample.UpdatedAt}</Moment></small>
                    </Card.Footer>
                </Card>
            )
        }

        let renderedPagination = [];

        if (this.state.total > this.state.page_size) {
            renderedPagination.push(
                <Row key={'pagination'}>
                    <Col>
                        <Button variant="outline-secondary" onClick={() => this.setState({ page: this.state.page - 1 })} disabled={this.state.page === 0}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Button>
                        <Button variant="outline-secondary" onClick={() => this.setState({ page: this.state.page + 1 })} disabled={this.state.page * this.state.page_size >= this.state.total}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </Col>
                </Row>
            )
        }

        return (
            <>
                <Card className="mb-3">
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col></Col>
                                <Col>
                                    <Image src={MonitoringImg} fluid />
                                </Col>
                                <Col></Col>
                            </Row>
                        </Container>
                        <InputGroup>
                            <Button onClick={this.handleOpenSampleModal} variant="primary">Create new sample</Button>

                            <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            <FormControl
                                onChange={this.onSearch}
                                placeholder="Search..."
                            />
                        </InputGroup>

                    </Card.Body>
                </Card>

                {renderedCards}

                <Pagination total={this.state.total} total_pages={this.state.total_pages} page_size={this.state.page_size} page={this.state.page} onChange={this.changePage} />
                <EditSampleModal show={this.state.sampleModalIsOpen} onUpdate={this.handleOnUpdateAgents} onClose={this.handleCloseSampleModal} {...this.props} />
            </>

        );
    }
}